// @flow

import * as React from 'react';
import { useState, useEffect } from 'react';
import idx from 'idx';
// import { Trans } from '@lingui/react';
// state
import {
  useCategoryProductsContainer,
  createCategoryContainer,
  createCategoryProductsContainer, useWebAnalyticsContainer,
} from '../app/state';
import type { PageWithMeta } from '../app/pages';
import type { ProductItem } from '../whyshop/models/product';
import type { CategoryDetailInfo } from '../whyshop/types';
import CategoryView from '../rudorfer/CategoryView';
import { createProductsPage } from '../root/createProductsPage';
import type { CategoryFilter } from '../appState/types';
import { useQueryParams } from '../app/router';
import { readQueryParams } from '../jsutils/location';
// import { usePrevious } from '../hooks/component';
import { useIsDebounced } from '../hooks/timers';
import { coerceNum } from '../jsutils/valutils';
import { mapSortOptions } from '../whyshop/models/category';
import type { SortOptionType } from '../whyshop/CategoryProductsContainer';
import { usePrevious } from '../hooks/component';

export const ALL_PRODUCTS = 'all';

function uniqueFilter() {
  let seen = new Set();
  return (a) => {
    const isUnique = !seen.has(a);
    seen.add(a);
    return isUnique;
  };
}

function usePagePrefetch(
  currentPage,
  opts: {
    debounceTimeout: number,
    prefetchPage: (page: number) => Promise<void>,
    lastPage: number,
    firstPage: number,
  }
) {
  const pageDebounced = useIsDebounced(currentPage, { timeout: 250, initiallyReady: true });

  // const storedPage = usePrevious(currentPage);

  // const [prefetched, setPrefetched] = useState([]);

  async function prefetchPages() {
    const nextPage = Math.min(currentPage + 1, opts.lastPage);

    // priority
    await opts.prefetchPage(nextPage);
    // if (storedPage !== prevPage) {
    //   await opts.prefetchPage(prevPage);
    // }

    // wait timeout
    // await opts.prefetchPage(opts.firstPage);
    // await opts.prefetchPage(opts.lastPage);

    // const prevPage = Math.max(currentPage - 1, opts.firstPage);
  }

  React.useEffect(() => {
    if (pageDebounced) {
      prefetchPages();
    }
  }, [currentPage, pageDebounced]);
}

function readPageParams(searchInput): Object {
  return readQueryParams(searchInput, {
    defaultParams: { attributes: [], filterNames: [], page: 1 },
    arrayFormat: 'comma',
    mapParams: ({ attributes, filterNames, page, ...rest }) => {
      return {
        page: coerceNum(page, 1),
        attributes: Array.isArray(attributes) ? attributes : [attributes],
        filterNames: Array.isArray(filterNames) ? filterNames : [filterNames],
        ...rest,
      };
    },
  });
}

function usePageParams(itemsCount): Object {
  return useQueryParams({
    defaultParams: { attributes: [], filterNames: [], page: 1 },
    arrayFormat: 'comma',
    mapParams: ({ attributes, filterNames, page, ...rest }) => {
      return {
        attributes: Array.isArray(attributes) ? attributes : [attributes],
        filterNames: Array.isArray(filterNames) ? filterNames : [filterNames],
        page: coerceNum(page, 1),
        ...rest,
      };
    },
  });
}

type LocalProps = {|
  categoryId: string,
  products: Array<ProductItem>,
  categoryInfo: CategoryDetailInfo,
  filters: Array<CategoryFilter>,
  sortOptions: Array<SortOptionType>,
  sortOption: ?SortOptionType,
  ...PageWithMeta,
|};

const SORT_PARAM_NAME = 'sort';

function CategoryPage(initialProps: LocalProps) {
  const { categoryId, categoryInfo, filters, sortOptions } = initialProps;

  const [products, setProducts] = useState<ProductItem[]>(initialProps.products);
  const [hasLoaded, setHasLoaded] = useState(true);
  const categoryProductsContainer = useCategoryProductsContainer();
  const [queryParams, setQueryParams] = usePageParams();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    attributes,
    filterNames,
    page,
    items_count = categoryProductsContainer.selectItemsCount(),
    ...namedFilters
  } = queryParams;
  const [infiniteScroll, setInfiniteScroll] = useState(items_count === ALL_PRODUCTS);
  const [sortOption, setSortOption] = useState(initialProps.sortOption);
  const webAnalytics = useWebAnalyticsContainer();

  const sortParam = {};
  if (sortOption) {
    sortParam[SORT_PARAM_NAME] = sortOption.sort;
  }

  useEffect(() => {
    onRefetch({ attributeIds: attributes, filterNames, namedFilters, page: 1, items_count });
  }, [sortOption]);

  const onRefetch = async ({ page, items_count, attributeIds, filterNames, namedFilters }) => {
    setHasLoaded(false);
    setProducts([]);
    setQueryParams({
      filterNames,
      page,
      items_count,
      attributes: attributeIds,
      ...namedFilters,
      ...sortParam,
    });
    const products = await categoryProductsContainer.getProducts(
      {
        categoryId,
        attributeIds,
        filterNames,
        namedFilters: { ...namedFilters, ...sortParam },
      },
      {
        page,
      }
    );

    webAnalytics.viewItemListEvent({categoryInfo, products, page, pageSize: items_count});
    setProducts(products);
    setHasLoaded(true);
  };

  const onLoadPage = async (nextPage: number) => {
    setQueryParams({ ...queryParams, page: nextPage });
    await onRefetch({
      filterNames,
      page: nextPage,
      items_count,
      attributeIds: attributes,
      namedFilters,
    });
  };

  const onFilter = (opts: {|
    attributeIds: Array<string>,
    filterNames: { [string]: $FlowFixMe },
    namedFilters: { [string]: string },
  |}) => {
    const resetPage = 1;
    const { namedFilters, filterNames } = opts;
    const attributes = opts.attributeIds.filter(uniqueFilter());
    onRefetch({
      page: resetPage,
      items_count,
      attributeIds: attributes,
      filterNames,
      namedFilters,
    });
  };

  const onItemsCountChange = async (itemsCount: number) => {
    if (itemsCount === ALL_PRODUCTS) {
      setInfiniteScroll(true);
    } else {
      setInfiniteScroll(false);
    }
    await categoryProductsContainer.setItemsCount(itemsCount);
    onRefetch({
      page: 1,
      items_count: itemsCount,
      attributeIds: attributes,
      filterNames,
      namedFilters,
    });
  };

  const onSortOptionChange = async (sort: string) => {
    const newOption = initialProps.sortOptions.find((s) => s.sort === sort);
    if (!newOption) return;
    const params = { ...queryParams };

    if (sortOption) {
      delete params[sortOption.param];
    }
    setQueryParams({ ...params, page: 1 });
    setSortOption(newOption);
    categoryProductsContainer.setSortOptionType(newOption.sort);
  };

  const onEnterWaypoint = async () => {
    setCurrentPage(currentPage + 1);
    const newProducts = await categoryProductsContainer.getProducts(
      {
        categoryId,
        attributeIds: attributes,
        filterNames,
        namedFilters: { ...namedFilters, ...sortParam },
      },
      {
        page: currentPage + 1,
      }
    );
    setProducts([...products, ...newProducts]);
  };

  const { title, descriptionHtml, images, subs, unrollFilters } = categoryInfo;

  const activeFilters = { attributeIds: attributes, filterNames, namedFilters };

  // consider memoizing
  const filterInfo = {
    filters,
    activeFilters,
    onChange: onFilter,
  };

  const resourceOptions = {
    categoryId,
    attributeIds: attributes,
    filterNames,
    namedFilters: { ...namedFilters, ...sortParam },
  };

  const paginationCounts = categoryProductsContainer.selectCounts(resourceOptions);
  const hasMore = categoryProductsContainer.hasPage(resourceOptions, { page: page + 1 });

  const pagination = {
    currentPage: page,
    onLoadPage,
    hasMore,
    pagesCount: paginationCounts.totalPages,
    totalItems: paginationCounts.totalItems,
    hasLoaded,
    itemsCount: items_count,
    onItemsCountChange,
  };

  const sorting = {
    options: mapSortOptions(sortOptions),
    option: sortOption,
    onChange: onSortOptionChange,
  };

  usePagePrefetch(infiniteScroll ? currentPage : page, {
    debounceTimeout: 250,
    prefetchPage: (page) => categoryProductsContainer.prefetchPage(resourceOptions, page),
    firstPage: 1,
    lastPage: paginationCounts.totalPages,
  });

  return (
    <CategoryView
      title={title}
      images={images}
      subs={subs}
      descriptionHtml={descriptionHtml}
      pagination={pagination}
      products={products}
      filterInfo={filterInfo}
      sorting={sorting}
      onEnterWaypoint={onEnterWaypoint}
      infiniteScroll={infiniteScroll}
      unrollFilters={unrollFilters}
    />
  );
}

function getSearchFromUrl(url = ''): string {
  return url.split('?')[1] || '';
}

export default createProductsPage<LocalProps>({
  Component: CategoryPage,
  getInitialProps: async ({ appContainer, match, ...ctx }) => {
    const categoryId = match.params.id;
    const { history, req } = ctx;

    const searchInput =
      idx(history, (_) => _.location.search) || getSearchFromUrl(idx(req, (_) => _.url) || '');

    const categoryContainer = await appContainer.resolve(createCategoryContainer);
    const categoryProductsContainer = await appContainer.resolve(createCategoryProductsContainer);

    const filters = await categoryContainer.getFilters(categoryId);
    const sortOptions = await categoryProductsContainer.loadSortOptions();
    const categoryInfo = await categoryContainer.getCategoryDetailInfo(categoryId);

    const { attributes, filterNames, page, items_count, ...namedFilters } = readPageParams(
      searchInput
    );
    if (items_count && items_count !== categoryProductsContainer.selectItemsCount()) {
      await categoryProductsContainer.setItemsCount(items_count);
    }

    let sortOption = null;
    Object.keys(namedFilters).some((key) => {
      sortOption = sortOptions.find((opt) => namedFilters[key] === opt.sort);
      return !!sortOption;
    });
    const sortOptionType = categoryProductsContainer.selectSortOptionType();

    if (sortOption && sortOption.sort !== sortOptionType) {
      // option is in url, but not in storage
      await categoryProductsContainer.setSortOptionType(sortOption.sort);
    }
    if (!sortOption && sortOptionType) {
      // option is in storage, but not in url
      sortOption = sortOptions.find((opt) => opt.sort === sortOptionType);
      if (sortOption) {
        namedFilters[SORT_PARAM_NAME] = sortOption.sort;
      }
    }
    if (!sortOption) {
      sortOption = sortOptions.find((s) => s.default);
      sortOption && (await categoryProductsContainer.setSortOptionType(sortOption.sort));
    }
    // NotePrototype(simon): read page from query (default 1)
    const products = await categoryProductsContainer.getProducts(
      {
        categoryId,
        attributeIds: attributes,
        filterNames,
        namedFilters,
      },
      { page }
    );

    return {
      products,
      categoryInfo,
      categoryId,
      filters,
      sortOptions,
      sortOption,
    };
  },
});
