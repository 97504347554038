// @flow

import * as React from 'react';
import { useRef } from 'react';

import type { ProductItem, FilterInfoPack } from '../appState/types';
// import ProductList from './ProductList';
import { Block, FlexRow, FlexColumn } from '../baseUI';
import { HorizontalSpace, VerticalSpace } from '../appUI';

import ProductFilters from './ProductFilters';
import { useMediaQuery } from '../hooks/responsive';
import { themeMUI, useLingui, useIsLogged } from '../app/state';
import { Trans } from '@lingui/react';
// import type { I18nType } from '../types/npm';
import { SelectControl } from '../materialUI';
import CategoryPagination from './CategoryPagination';
import LoadingInline from '../appUI/LoadingInline';
import Grid from '@material-ui/core/Grid/Grid';
import Button from '@material-ui/core/Button/Button';
import { ChangeListLayoutView, useListLayout } from '../RudorferApp/list-layout';
import ProductList from '../rudorfer/ProductList';
import { useObjectState } from '../hooks/component';
import { EmptyPageTitle, PageTitle } from '../appUI/typography';
import { smoothScrollMiddleToElement } from '../jsutils/browser';
import type { SortOptionType } from '../whyshop/CategoryProductsContainer';
import type { I18nType } from '../types/npm';
import { PaginatedProductsCount } from '../app/config';
import { Waypoint } from 'react-waypoint';
import { ALL_PRODUCTS } from '../RudorferApp/CategoryPage';

import { useGlobalContainer } from '../app/state';
import AlfaboxSelect from './AlfaboxSelect';
import AlfaboxRibbon from './AlfaboxRibbon';
import { makeStyles } from '@material-ui/core';
import RudorferGallery from './RudorferGallery';
import { mapFiltersFromQueryParams } from './mapFiltersFromQueryParams';
import CategorySlider from './CategorySlider';
import { FilterInfoContext } from './FilterInfoContext';
import styled from '@emotion/styled';

const GalleryWrapper = styled.div`
  /* & .image-gallery-slide .image-gallery-image {
    height: auto !important;
  } */
`;

const SortSelect = (props) => {
  const { i18n } = props;
  const globalContainer = useGlobalContainer();
  const loggedIn = useIsLogged();

  const selectOptions = props.options
    ? props.options.filter((opt) => (opt.authNeeded && !loggedIn ? false : true))
    : props.options;

  return (
    <React.Fragment>
      {globalContainer.isSpecificProject('alfabox') ? (
        <AlfaboxSelect
          value={props.value}
          onChange={props.onChange}
          options={selectOptions}
          inputProps={{
            id: 'sort-select',
          }}
          label={i18n.t`Sort by`}
        />
      ) : (
        <SelectControl
          value={props.value}
          onChange={props.onChange}
          options={selectOptions}
          inputProps={{
            id: 'sort-select',
          }}
          label={i18n.t`Sort by`}
          formControlProps={{ fullWidth: true }}
          inputLabelProps={{ shrink: true }}
        />
      )}
    </React.Fragment>
  );
};

const ItemsCountSelect = (props: {|
  i18n: I18nType,
  totalItems: number,
  value: number,
  onChange: Function,
|}) => {
  const { i18n } = props;
  const options = [
    { value: PaginatedProductsCount, label: `${PaginatedProductsCount} ${i18n.t`items per page`}` },
    {
      value: PaginatedProductsCount * 2,
      label: `${PaginatedProductsCount * 2} ${i18n.t`items per page`}`,
    },
    { value: ALL_PRODUCTS, label: `${i18n.t`All products`} (${props.totalItems})` },
  ];
  const globalContainer = useGlobalContainer();
  return (
    <React.Fragment>
      {globalContainer.isSpecificProject('alfabox') ? (
        <AlfaboxSelect
          value={props.value}
          onChange={props.onChange}
          options={options}
          inputProps={{
            id: 'items-count-select',
          }}
          label={i18n.t`Show`}
        />
      ) : (
        <SelectControl
          value={props.value}
          onChange={props.onChange}
          options={options}
          inputProps={{
            id: 'items-count-select',
          }}
          label={i18n.t`Show`}
          formControlProps={{ fullWidth: true }}
          inputLabelProps={{ shrink: true }}
        />
      )}
    </React.Fragment>
  );
};

type LocalProps = {|
  filterInfo: FilterInfoPack,

  pagination: {|
    pagesCount: number,
    itemsCount: number,
    totalItems: number,

    hasLoaded: boolean,
    hasMore: boolean,
    onLoadPage: (number) => Promise<void>,
    currentPage: number,
    onItemsCountChange: Function,
  |},
  sorting: {|
    option: ?SortOptionType,
    options: Array<{| value: string, label: string, authNeeded: boolean |}>,
    onChange: Function,
  |},

  products: Array<ProductItem>,

  title: string,
  descriptionHtml: string,
  subs: *,
  images: Array<{| original: string, thumbnail: string |}>,
  unrollFilters: boolean,
  onEnterWaypoint: Function,
  infiniteScroll: boolean,
|};

type LocalState = {|
  // filters: string[],
  showAllSubs: boolean,
  showAllOptions: boolean,
|};

function CategoryView(props: LocalProps) {
  const { filterInfo } = props;

  const i18n = useLingui();
  const [layoutVariant] = useListLayout();
  const topPaginationRef = useRef();
  // const isLogged = useLoginContainer().isLogged();
  const isUp500 = useMediaQuery(`(min-width: ${500}px)`);
  const isUp670 = useMediaQuery(`(min-width: ${650}px)`);
  const isUp850 = useMediaQuery(`(min-width: ${830}px)`);
  const isUp1200 = useMediaQuery(`(min-width: ${1200}px)`);
  const isUp1400 = useMediaQuery(`(min-width: ${1400}px)`);
  const isSmall = useMediaQuery(themeMUI.breakpoints.down('xs'));

  const isMedium = useMediaQuery(themeMUI.breakpoints.down('sm'));

  const globalContainer = useGlobalContainer();

  let perSlideCount = 1;
  if (isUp1400) {
    perSlideCount = 6;
  } else if (isUp1200) {
    perSlideCount = 5;
  } else if (isUp850) {
    perSlideCount = 4;
  } else if (isUp670) {
    perSlideCount = 3;
  } else if (isUp500) {
    perSlideCount = 2;
  }

  const [state, setState] = useObjectState<LocalState>({
    // filters: [],
    showAllSubs: false,
    showAllOptions: false,
  });

  // get filters options labeled img-gallery (only for "NEW" category)
  const { filters } = filterInfo;
  const filterOptions = (() => {
    if (filters) {
      // find the 'img-gallery' filter
      const tempOptions = filters.find((el) => el.filterType === 'img-gallery');
      if (!tempOptions) return null;
      // flow condition
      const options =
        tempOptions && tempOptions.imgGalleryOptions ? tempOptions.imgGalleryOptions : [];
      // map attributes of each option to new attributes
      return options.map((opt) => ({
        title: opt.label,
        url: opt.value,
        imageSrc: opt.image,
      }));
    }
  })();

  const {
    title,
    descriptionHtml,
    products,
    images,
    subs,
    pagination,
    sorting,
    infiniteScroll,
  } = props;

  const hasImages = !!images.length;
  // NotePrototype(simon): improve?
  const isTopCategory = !!subs.length;

  const { hasMore, hasLoaded } = pagination;

  const showNoResults = hasLoaded && !hasMore && products.length === 0;
  const showAllLoaded = hasLoaded && !hasMore && products.length !== 0;
  const showTopPagination = !infiniteScroll && !showNoResults;
  const showBottomPagination = !infiniteScroll && !showNoResults && hasLoaded;

  const onSetPage = async (currentPage) => {
    await pagination.onLoadPage(currentPage);
    const el = topPaginationRef.current;
    if (el) {
      smoothScrollMiddleToElement(el);
    }
  };

  const renderRudorferCategoryProducts = () => {
    return (
      <React.Fragment>
        <PageTitle>{title}</PageTitle>
        <VerticalSpace />
        {isTopCategory ? (
          <>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <div
                  className="serverMarkup"
                  dangerouslySetInnerHTML={{ __html: descriptionHtml }}
                />
              </Grid>
            </Grid>
            <VerticalSpace size="large" />
            <FlexColumn>
              <CategorySlider
                showAll={state.showAllSubs}
                items={subs}
                perSlideCount={perSlideCount}
                isFilterGallery={false}
              />
              <VerticalSpace />
              <FlexRow justifyContent="center">
                <Button
                  color="primary"
                  onClick={() => setState({ showAllSubs: !state.showAllSubs })}
                >
                  {state.showAllSubs ? <Trans>See less</Trans> : <Trans>See all</Trans>}
                </Button>
              </FlexRow>
            </FlexColumn>
          </>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <div className="serverMarkup" dangerouslySetInnerHTML={{ __html: descriptionHtml }} />
            </Grid>
            {hasImages && (
              <Grid item xs={12} sm={6}>
                <GalleryWrapper>
                  <RudorferGallery items={images} thumbnailPosition="right" />
                </GalleryWrapper>
              </Grid>
            )}
          </Grid>
        )}
        <VerticalSpace size="large" />
        {filterOptions && filterOptions.length ? (
          <FilterInfoContext.Provider value={filterInfo}>
            <CategorySlider
              showAll={state.showAllOptions}
              items={filterOptions}
              perSlideCount={perSlideCount}
              isFilterGallery={true}
            />
          </FilterInfoContext.Provider>
        ) : null}
        <VerticalSpace />
        <ProductFilters defaultExpanded={props.unrollFilters} filterInfo={filterInfo} />
        <VerticalSpace size="large" />
        {!showNoResults && (
          <FlexRow flexWrap="wrap" alignItems="center">
            <ChangeListLayoutView />
            <FlexRow flexDirection={isSmall && 'column'} width="80%" alignItems="center">
              <Block width={250} marginLeft={20} marginBottom={10}>
                <ItemsCountSelect
                  i18n={i18n}
                  onChange={(e) => pagination.onItemsCountChange(e.target.value)}
                  value={pagination.itemsCount}
                  totalItems={pagination.totalItems}
                />
              </Block>
              <Block width={250} marginLeft={20} marginBottom={10}>
                <SortSelect
                  i18n={i18n}
                  onChange={(e) => sorting.onChange(e.target.value)}
                  value={sorting.option ? sorting.option.sort : ''}
                  options={sorting.options}
                />
              </Block>
            </FlexRow>
          </FlexRow>
        )}
        {showTopPagination && (
          <div ref={topPaginationRef}>
            <FlexRow
              className="CategoryPagination"
              justifyContent={isSmall ? 'center' : 'flex-end'}
              marginTop={20}
            >
              <CategoryPagination
                pageCount={pagination.pagesCount}
                currentPage={pagination.currentPage}
                setPage={onSetPage}
              />
            </FlexRow>
          </div>
        )}
        <VerticalSpace />
        {hasLoaded ? (
          <ProductList variant={layoutVariant} products={products} />
        ) : (
          <LoadingInline />
        )}
        {infiniteScroll && <Waypoint bottomOffset="-1000px" onEnter={props.onEnterWaypoint} />}
        {showBottomPagination && (
          <FlexRow justifyContent={isSmall ? 'center' : 'flex-end'} marginBottom={20}>
            <CategoryPagination
              pageCount={pagination.pagesCount}
              currentPage={pagination.currentPage}
              setPage={onSetPage}
            />
          </FlexRow>
        )}
        <VerticalSpace />
        {showNoResults && (
          <FlexRow justifyContent="center">
            <EmptyPageTitle>
              <Trans>No products found.</Trans>
            </EmptyPageTitle>
          </FlexRow>
        )}
        {showAllLoaded && (
          <FlexRow justifyContent="center">
            <Trans>All items has been loaded.</Trans>
          </FlexRow>
        )}
      </React.Fragment>
    );
  };

  const useStyles = makeStyles((theme) => ({
    button: {
      display: 'block',
      margin: '5px 10px 0 10px',
      textTransform: 'capitalize',
      [theme.breakpoints.down('xs')]: {
        margin: '5px 0 0 0',
      },
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  }));

  const renderAlfaboxCategoryProducts = () => {
    const classes = useStyles(themeMUI);
    return (
      <React.Fragment>
        <AlfaboxRibbon title={title} isSubtitle={true} />
        {!showNoResults && (
          <FlexRow flexWrap="wrap" alignItems="center" style={{ marginTop: '-3rem' }}>
            <ChangeListLayoutView />
            <FlexRow
              flexDirection={isSmall && 'column'}
              alignItems="center"
              justifyContent="space-between"
              flex="1"
            >
              <FlexRow
                width={isSmall && '100%'}
                justifyContent={isSmall && 'space-between'}
                alignItems="center"
              >
                <Block>
                  <ItemsCountSelect
                    i18n={i18n}
                    onChange={(e) => pagination.onItemsCountChange(e.target.value)}
                    value={pagination.itemsCount}
                    totalItems={pagination.totalItems}
                  />
                </Block>
                <Block>
                  <SortSelect
                    i18n={i18n}
                    onChange={(e) => sorting.onChange(e.target.value)}
                    value={sorting.option ? sorting.option.sort : ''}
                    options={sorting.options}
                  />
                </Block>
              </FlexRow>
              <FlexRow width={isSmall && '100%'} justifyContent={isSmall && 'space-between'}>
                <Block>
                  <Button className={classes.button}>
                    <Trans>price</Trans>
                  </Button>
                </Block>
                <Block>
                  <Button className={classes.button}>
                    <Trans>purpose</Trans>
                  </Button>
                </Block>
                <Block>
                  <Button className={classes.button}>
                    <Trans>colour</Trans>
                  </Button>
                </Block>
              </FlexRow>

              {showTopPagination && (
                <div ref={topPaginationRef}>
                  <FlexRow
                    className="CategoryPagination"
                    justifyContent={isSmall ? 'center' : 'flex-end'}
                    marginTop={isSmall ? '20' : '5'}
                  >
                    <CategoryPagination
                      pageCount={pagination.pagesCount}
                      currentPage={pagination.currentPage}
                      setPage={onSetPage}
                      isAlfabox={true}
                    />
                  </FlexRow>
                </div>
              )}
            </FlexRow>
          </FlexRow>
        )}

        <VerticalSpace />
        {hasLoaded ? (
          <ProductList variant={layoutVariant} products={products} />
        ) : (
          <LoadingInline />
        )}
        {infiniteScroll && <Waypoint bottomOffset="-1000px" onEnter={props.onEnterWaypoint} />}
        {showBottomPagination && (
          <FlexRow
            justifyContent={isSmall ? 'center' : 'flex-end'}
            marginBottom={20}
            marginTop={20}
          >
            <CategoryPagination
              pageCount={pagination.pagesCount}
              currentPage={pagination.currentPage}
              setPage={onSetPage}
              isAlfabox={true}
            />
          </FlexRow>
        )}
        <VerticalSpace />
        {showNoResults && (
          <FlexRow justifyContent="center">
            <EmptyPageTitle>
              <Trans>No products found.</Trans>
            </EmptyPageTitle>
          </FlexRow>
        )}
        {showAllLoaded && (
          <FlexRow justifyContent="center">
            <Trans>All items has been loaded.</Trans>
          </FlexRow>
        )}
      </React.Fragment>
    );
  };

  return (
    <Block width="100%">
      {globalContainer.isSpecificProject('alfabox')
        ? renderAlfaboxCategoryProducts()
        : renderRudorferCategoryProducts()}
    </Block>
  );
}

export default CategoryView;
