// @flow

import idx from 'idx';
import type {
  SubCategoryItem,
  TopCategoryItem,
  CategoryDetailInfo,
  CategoryLabelsType,
  CategoryLabelsTestType,
  ImageModel,
} from '../types';
import type { ImageJsonType, PageAttributes } from '../jsonTypes';
import type { CategoryFilter, CategoryFiltersArray } from '../../appState/types';
import type { SortOptionType } from '../CategoryProductsContainer';
import type { ProductLabelsTestType } from '../../appState/types';

// import * as React from 'react';

//
// API
//

type BackendID = number;
type LabelsJSON = { [string]: boolean };
type LabelsTestJSON = [{ [string]: boolean }];

export type CategoryDetail = {|
  id: BackendID,
  name: string,
  long_name: string,
  attributes: Array<{| id: number, name: string, value: string |}>,
  description_html: ?string,
  main_image?: ImageJsonType,
  images: ImageJsonType[],
  custom_url?: string,
  parent_id?: number,
  children: CategoryDetail[],
  icon: ?string,
  labels?: LabelsJSON,
  labels_test?: LabelsTestJSON,
  page_attributes?: PageAttributes,
|};

export type CategoryDetailInfoPayload = {|
  info: {|
    children: CategoryDetail[],
    main_image: ?ImageJsonType,
    images: ImageJsonType[],

    id: BackendID,
    name: string,
    long_name: string,
    is_special: boolean,
    description_html: ?string,
    custom_url?: string,
    parent_id?: number,
    labels?: LabelsJSON,
    labels_test?: LabelsTestJSON,
    page_attributes?: PageAttributes,
    options: {|
      unroll_filters: boolean,
    |},
  |},
|};

type FilterCommon = {|
  id: BackendID,
  name: string,
  slug: string,
  auth_needed: boolean,
|};

// {|
//   id: number,
//   value: string,
// |}
// {|
//   min: number,
//   max: number,
// |},

type IntervalFormatPayload = {|
  type: 'price',
  unit: string, // CZK
  price: {|
    currency: string, // CZK
  |},
|};

type FilterPayload =
  | {|
      ...FilterCommon,

      type: 'select' | 'multiselect',
      options: Array<{| id: number, value: string |}>,
    |}
  | {|
      ...FilterCommon,

      type: 'img-gallery',
      options: Array<{| id: number, value: string, image: ImageJsonType |}>,
    |}
  | {|
      ...FilterCommon,
      type: 'bool',
      bool: BackendID,
    |}
  | {|
      ...FilterCommon,
      type: 'interval',
      interval: {| min: number, max: number, step: number, format: IntervalFormatPayload |},
    |};

export const resources = {
  listCategories: { url: `/api/product/category/` },
  categoryInfo: (id: string) => ({ url: `/api/product/category/${id}/` }),
  filters: (id: string) => ({ url: `/api/product/filters/${id}/` }),
};

//
// Mapping
//

function mapImage(payload): ImageModel {
  return {
    original: idx(payload, (_) => _.main_image.big) || '',
    thumbnail: idx(payload, (_) => _.main_image.small) || '',
  };
}

// Note: backend returns nulls in array :(
function createImageFromJSON(imgPayload: ?ImageJsonType): ImageModel {
  return {
    original: (imgPayload && imgPayload.big) || '',
    thumbnail: (imgPayload && imgPayload.small) || '',
  };
}

function mapCategoryChild(payload, mapLabels, mapLabelsTest): SubCategoryItem {
  const subId = String(payload.id);
  return {
    id: subId,
    categoryUrl: idx(payload, (_) => _.page_attributes.url) || '',
    image: mapImage(payload),
    title: payload.name,
    labels: mapLabels(payload.labels),
    labels_test: mapLabelsTest(payload.labels_test),
  };
}

export function mapCategory(
  payload: CategoryDetail,
  opts: {|
    mapLabels: (?LabelsJSON) => CategoryLabelsType,
    mapLabelsTest: (?LabelsTestJSON) => Array<ProductLabelsTestType>,
  |}
): TopCategoryItem {
  const id = String(payload.id);

  return {
    id,

    title: payload.name,
    labels: opts.mapLabels(payload.labels),
    labels_test: opts.mapLabelsTest(payload.labels_test),
    categoryUrl: idx(payload, (_) => _.page_attributes.url) || '',
    image: mapImage(payload),

    // top-level only

    // NotePrototype: add some icon so it can be used on mobile
    iconUrl: payload.icon || '',
    subs: payload.children.map((_) => mapCategoryChild(_, opts.mapLabels, opts.mapLabelsTest)),
    // isSale: false,
  };
}

export function mapCategoryDetailInfo(payload: CategoryDetailInfoPayload): CategoryDetailInfo {
  const { info } = payload;

  // children: CategoryDetail[],
  // main_image: ?ImageJsonType,
  // images: ImageJsonType[],

  return {
    title: info.name || '',
    descriptionHtml: info.description_html || '',
    id: String(info.id),
    subs: (info.children || []).map((child) => {
      return {
        title: idx(child, (_) => _.name) || '',
        imageSrc: idx(child, (_) => _.main_image.small) || '',
        url: idx(child, (_) => _.page_attributes.url) || '',
      };
    }),
    isSpecial: info.is_special,
    images: info.images.map(createImageFromJSON),
    unrollFilters: idx(info, (_) => _.options.unroll_filters) || false,
  };
}

export function mapFilters(payload: Array<FilterPayload>): CategoryFiltersArray {
  return {
    id: String(payload.toString()),
    categoryFilters: payload.map((item) => {
      const { name, auth_needed: authNeeded } = item;
      if (item.type === 'interval') {
        const { interval } = item;
        return {
          id: name,
          name,
          paramName: item.slug,
          filterType: 'interval',
          interval: {
            min: interval.min,
            max: interval.max,
            step: interval.step,
            format: interval.format,
          },
          authNeeded,
          // options: [],
          // boolId: '',
        };
      }
      if (item.type === 'select' || item.type === 'multiselect') {
        const { options } = item;
        return {
          id: name,
          name,
          paramName: item.slug,
          filterType: item.type,
          options: options.map((opt) => ({ value: String(opt.id), label: opt.value })),
          authNeeded,
          // interval: { min: 0, max: 0 },
          // boolId: '',
        };
      }
      if (item.type === 'bool') {
        const boolId = String(item.bool);
        return {
          id: name,
          name,
          paramName: item.slug,
          filterType: 'bool',
          boolId,
          authNeeded,
          // interval: { min: 0, max: 0 },
          // options: [],
        };
      }
      if (item.type === 'img-gallery') {
        const { options } = item;

        return {
          id: name,
          name,
          paramName: item.slug,
          filterType: item.type,
          imgGalleryOptions: options.map((opt) => ({
            value: String(opt.id),
            label: opt.value,
            image: idx(opt, (_) => _.image.small) || '',
          })),
          authNeeded,
        };
      }
      // eslint-disable-next-line no-unused-expressions
      (item.type: empty);
      throw new Error('Impossible');
    }),
  };
}

export function mapSortOptions(
  data: Array<SortOptionType>
): Array<{| value: string, label: string, authNeeded: boolean |}> {
  return data.map((s) => ({
    value: s.sort,
    label: s.name,
    authNeeded: s.auth_needed,
  }));
}
