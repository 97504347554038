// @flow

export const sentryDSN = 'https://a4dd752aa460475598f3326329c016fd@coffee.whys.eu/15';

//

type EnvironmentEnum = 'development' | 'production';

function coerceEnvironment(env): EnvironmentEnum {
  if (env === 'development') {
    return env;
  }
  return 'production';
}

export const environment = coerceEnvironment(process.env.NODE_ENV);
